import { Input, Space } from "antd";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import IconWarning from "../base/svgIcons/IconWarning";

interface InputFieldProps {
  name: string;
  label: string;
  control: any;
  errors: Object;
  isRequired?: boolean; //If true, adds * next to the input field
  capitalizeFirstLetter?: boolean;
  validationMessage?: string;
  defaultValue?: string;
  disabled?: boolean;
}

const PartInputField = ({
  name,
  label,
  control,
  errors,
  isRequired,
  validationMessage,
  defaultValue = "",
  disabled
}: InputFieldProps) => {
  return (
    <div
      className={`form-element form-element--text ${
        name in errors ? "form-element--error" : ""
      }`}
    >
      <label>
        {label}
        {isRequired && <span>{" *"}</span>}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Input
            {...field}
            allowClear
            disabled={disabled}
            suffix={name in errors && <IconWarning width={16} fill="#a0782a" />}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <p className="form-element__error-msg">
            {validationMessage ? validationMessage : message}
          </p>
        )}
      />
      <Space />
    </div>
  );
};

export default PartInputField;
