import TypeString from "../valueObject/TypeString";
import TypeTimestamp from "../valueObject/TypeTimestamp";

export default class ProjectEntity {
  _id: TypeString;
  _client: TypeString;
  _clientEmail: TypeString;
  _color: TypeString;
  _createdAt: TypeTimestamp;
  _name: TypeString;
  _updatedAt: TypeTimestamp;

  get id(): TypeString {
    return this._id;
  }
  setId(value: TypeString) {
    this._id = value;
    return this;
  }

  hasId() {
    return this._id instanceof TypeString;
  }

  get client(): TypeString {
    return this._client;
  }
  setClient(value: TypeString) {
    this._client = value;
    return this;
  }

  hasClient() {
    return this._client instanceof TypeString;
  }

  get clientEmail(): TypeString {
    return this._clientEmail;
  }
  setClientEmail(value: TypeString) {
    this._clientEmail = value;
    return this;
  }

  hasClientEmail() {
    return this._clientEmail instanceof TypeString;
  }

  get color(): TypeString {
    return this._color;
  }
  setColor(value: TypeString) {
    this._color = value;
    return this;
  }
  hasColor() {
    return this._color instanceof TypeString;
  }

  get createdAt(): TypeTimestamp {
    return this._createdAt;
  }
  setCreatedAt(value: TypeTimestamp) {
    this._createdAt = value;
    return this;
  }
  hasCreatedAt() {
    return this._createdAt instanceof TypeTimestamp;
  }

  public get name(): TypeString {
    return this._name;
  }
  setName(value: TypeString) {
    this._name = value;
    return this;
  }
  hasName() {
    return this._name instanceof TypeString;
  }

  get updatedAt(): TypeTimestamp {
    return this._updatedAt;
  }
  setUpdatedAt(value: TypeTimestamp) {
    this._updatedAt = value;
    return this;
  }
  hasUpdatedAt() {
    return this._updatedAt instanceof TypeTimestamp;
  }
}
