import React, { useEffect, useRef } from "react";
import { Input, Space } from "antd";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

interface InputFieldProps {
  name: string;
  classWrapper?: string;
  label?: string;
  control: any;
  errors: Object;
  isRequired?: boolean; //If true, adds * next to the input field
  capitalizeFirstLetter?: boolean;
  rows?: number; // how many rows to span (default 4)
  validationMessage?: string;
  showCount?: boolean;
  maxLength?: number;
  disabled?: boolean;
}

const PartTextAreaInput = ({
  name,
  classWrapper,
  label,
  control,
  errors,
  isRequired,
  rows = 4,
  validationMessage,
  showCount = false,
  maxLength,
  disabled
}: InputFieldProps) => {
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (inputRef) inputRef.current.blur();
  }, []);

  return (
    <div
      className={`form-element ${name in errors ? "form-element--error" : ""}`}
    >
      {label && (
        <label>
          {label}
          {isRequired && <span>{" *"}</span>}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <Input.TextArea
            {...field}
            className={classWrapper}
            disabled={disabled}
            rows={rows}
            allowClear
            ref={inputRef}
            showCount={showCount}
            maxLength={maxLength}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <p className="form-element__error-msg">
            {validationMessage ? validationMessage : message}
          </p>
        )}
      />
      <Space />
    </div>
  );
};

export default PartTextAreaInput;
