import { Input, Space } from "antd";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import IconEyeSlash from "../base/svgIcons/IconEyeSlash";
import IconEye from "../base/svgIcons/IconEye";
import { useState } from "react";
import { PASSWORD_REGULAR_EXPRESSION } from "../../utils/formValidationSchemas";
import IconCloseCircle from "../base/svgIcons/IconCloseCircle";
import IconCheckCircle from "../base/svgIcons/IconCheckCircle";

interface InputFieldProps {
  name: string;
  label: string;
  control: any;
  errors: Object;
  isRequired?: boolean; //If true, adds * next to the input field
  capitalizeFirstLetter?: boolean;
  validationMessage?: string;
  defaultValue?: string;
  validateRegexOnChange?: boolean;
  passwordsMatch?: boolean;
}

const PartInputPassword = ({
  name,
  label,
  control,
  errors,
  isRequired,
  defaultValue = "",
  validateRegexOnChange,
  passwordsMatch
}: InputFieldProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
    <div
      className={`form-element form-element--password ${
        name in errors ? "form-element--error" : ""
      }`}
    >
      <label>
        {label}
        {isRequired && <span>{" *"}</span>}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => {
          let validPassword = false;
          if (validateRegexOnChange)
            validPassword = PASSWORD_REGULAR_EXPRESSION.test(field.value);
          return (
            <Input
              {...field}
              type={passwordVisible ? "text" : "password"}
              suffix={
                <>
                  {validateRegexOnChange ? (
                    validPassword ? (
                      <span className="form-element__icon form-element__icon--valid">
                        <IconCheckCircle />
                      </span>
                    ) : field.value.length ? (
                      <span className="form-element__icon form-element__icon--invalid">
                        <IconCloseCircle />
                      </span>
                    ) : null
                  ) : null}
                  {field.value.length && passwordsMatch !== undefined ? (
                    passwordsMatch ? (
                      <span className="form-element__icon form-element__icon--valid">
                        <IconCheckCircle />
                      </span>
                    ) : field.value.length ? (
                      <span className="form-element__icon form-element__icon--invalid">
                        <IconCloseCircle />
                      </span>
                    ) : null
                  ) : null}
                  {passwordVisible ? (
                    <span onClick={() => setPasswordVisible(false)}>
                      <IconEyeSlash />
                    </span>
                  ) : (
                    <span onClick={() => setPasswordVisible(true)}>
                      <IconEye />
                    </span>
                  )}
                </>
              }
            />
          );
        }}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <p className="form-element__error-msg">{message}</p>
        )}
      />
      <Space />
    </div>
  );
};

export default PartInputPassword;
