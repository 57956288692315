import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidationSchema } from "../../utils/formValidationSchemas";
import { Button, Form } from "antd";
import CredentialsService from "../../domain/credentials/CredentialsService";
import CredentialsType from "../../domain/credentials/valueObject/CredentialsType";
import commonSliceState, { setIsLoading } from "../../store/common";
import store from "../../store/storeIndex";
import PartInputField from "../parts/PartInputField";
import PartInputPassword from "../parts/PartInputPassword";
import { Link, useHistory } from "react-router-dom";
import { setUserId, setUsername } from "../../store/auth";
import PopupsController from "../../utils/popup/PopupsController";
import { useSelector } from "react-redux";
import CredentialsFactory from "../../domain/credentials/CredentialsFactory";
import TypeString from "../../domain/valueObject/TypeString";

export interface LoginFormTypes {
  email: string;
  password: string;
  type: string;
}

interface LoginFormProps {
  onLoginHandler?: CallableFunction;
}

const BlockLoginForm = ({ onLoginHandler }: LoginFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginFormTypes>({
    resolver: yupResolver(loginValidationSchema),
    mode: "onBlur"
  });

  const commonState = useSelector(commonSliceState);
  const history = useHistory();

  const [invalidLoginMessage, setInvalidLoginMessage] = useState("");

  useEffect(() => {
    if (commonState.isLoading) store.dispatch(setIsLoading(false));
  }, []);

  const onSubmit = async (data: LoginFormTypes) => {
    try {
      store.dispatch(setIsLoading(true));
      // const credentials = CredentialsFactory.makeNew(
      //   new TypeString(data.email),
      //   new TypeString(data.password),
      //   CredentialsType.makeEmail()
      // );
      const credentials = { email: data.email, password: data.password };
      const response = await CredentialsService.login(credentials);

      console.log(response);
      localStorage.setItem("token", response.token);

      // const me = await CredentialsService.me();
      // if (me.hasUser()) {
      //   localStorage.setItem("isLoggedIn", "true");
      //   const username = me.user.firstName.value;
      //   store.dispatch(setUsername(username));
      //   store.dispatch(setUserId(me.user.id.value));
      // }
      if (typeof onLoginHandler === "function") onLoginHandler();
    } catch (exception) {
      setInvalidLoginMessage(
        "Email or password are not valid. Please try again."
      );
    } finally {
      store.dispatch(setIsLoading(false));
    }
  };

  return (
    <Form>
      <PartInputField
        name="email"
        label="Email"
        control={control}
        errors={errors}
        defaultValue="jelena.ruzic@forwardslashny.com"
        isRequired
      />
      <PartInputPassword
        name="password"
        label="Password"
        control={control}
        errors={errors}
        defaultValue="t95Lvh5SYn9E$"
        isRequired
      />
      <Button
        className="primary-btn"
        type="primary"
        onClick={handleSubmit(onSubmit)}
      >
        Login
      </Button>
      {/* <Link
        className="login-block__link"
        to="/reset-password"
        onClick={() => {
          history.push("/reset-password", true);
          localStorage.removeItem("isLoggedIn");
          PopupsController.closeLoginPopup();
        }}
      >
        Forgot your password?
      </Link> */}
      {/* <span className="login-block__invalid-login">{invalidLoginMessage}</span> */}
    </Form>
  );
};

export default BlockLoginForm;
