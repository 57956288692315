import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import EventEntity from "./EventEntity";

// type EventResponseType = {
//   _id: TypeString;
//   description: TypeString;
//   time: number;
//   date: TypeTimestamp;
//   project: any;
//   createdAt: TypeTimestamp;
//   updatedAt: TypeTimestamp;
//   type: any;
// };

export default class EventFactory {
  reconstitute(data: Collection) {
    const events: Array<EventEntity> = [];

    data.forEach((event: any) => {
      const eventInstance = this.makeEmpty();
      eventInstance
        .setId(event._id)
        .setDescription(new TypeString(event.description))
        .setTime(event.time)
        .setDate(event.date)
        .setProject(event.project)
        .setCreatedAt(event.createdAt)
        .setUpdatedAt(event.updatedAt)
        .setType(event.type);
      events.push(eventInstance);
    });

    // loop through events and merge ones with the same date and project (sum up hours)
    const mergedEventsByDate = this.mergeEventsByDate(events);
    const mergedEventsByProject = this.mergeEventsByProject(mergedEventsByDate);
    return mergedEventsByProject;
    // return events;
  }
  mergeEventsByProject(mergedEventsByDate: any) {
    const newEvents: any = [];
    mergedEventsByDate.forEach((dateEvent: any) => {
      if (newEvents.find((newE: any) => newE.date === dateEvent.date)) {
        // TO-DO
        console.log("date already exists, update it");
      } else {
        const clients: any = [];

        dateEvent.events.forEach((event: any) => {
          if (
            clients.find((client: any) => client.client === event.project.name)
          ) {
            const index = clients.findIndex((cl: any) => {
              return cl.client === event._project.name;
            });
            clients[index].time += event._time;
          } else {
            const newProj = {
              eventId: event.id,
              client: event.project.name,
              time: event._time,
              type: event.type,
              description: event?.description?.value,
              project: event.project,
              color: event.project.color,
              date: dateEvent.date
            };
            clients.push(newProj);
          }
        });

        const newObj = {
          date: dateEvent.date,
          hours: dateEvent.hours,
          clients
        };
        newEvents.push(newObj);
      }
    });
    console.log(newEvents, "newEvents final");
    console.log(mergedEventsByDate, "mergedEventsByDate");
    return newEvents;
  }

  mergeEventsByDate(events: any) {
    const groups = events.reduce((groups: any, event: any) => {
      const date = event.date.split("T")[0];
      if (!groups[date]) groups[date] = [];
      groups[date].push(event);
      return groups;
    }, {});

    const groupArrays: any = Object.keys(groups).map((date) => {
      let hours = 0;
      for (let i = 0; i < groups[date].length; i++) {
        hours += groups[date][i].time;
      }

      return {
        date,
        hours,
        events: groups[date]
      };
    });

    return groupArrays;
  }

  makeEmpty(): EventEntity {
    return new EventEntity();
  }

  static makeNewFromForm(formData: any) {
    const data = {
      date: formData.formattedDate,
      description: formData.description,
      project: formData.project,
      type: formData.type,
      time: parseInt(formData.hours)
    };

    return data;
  }
}
