import Exception from "../../utils/exceptions/Exception";
import BaseValueObject from "./BaseValueObject";

export default class TypeInteger extends BaseValueObject {
  constructor(value: any) {
    super(parseInt(value));
    this._validateValue(value);
  }

  _validateValue(value: any) {
    if (value % 1 !== 0 || parseInt(value) !== value)
      throw new Exception(`Expected integer got ${value}`, 406);
  }
}
