import AdminEntity from "../admin/AdminEntity";
import AdminFactory from "../admin/AdminFactory";
import CSRFTokenFactory from "../CSRFToken/CSRFTokenFactory";
import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import CredentialsEntity from "./CredentialsEntity";
import MeEntity from "./MeEntity";
import CredentialsType from "./valueObject/CredentialsType";

export default class CredentialsFactory {
  reconstitute(data: Collection) {
    return CredentialsFactory.makeMe(
      new CSRFTokenFactory().reconstitute(data),
      AdminFactory.reconstitute(data.extract("user"))
    );
  }

  static makeNew(
    representer: TypeString,
    payload: TypeString,
    type: CredentialsType
  ) {
    return new CredentialsEntity(representer, payload, type);
  }

  static makeMe(csrfToken: TypeString, admin: AdminEntity) {
    return new MeEntity().setToken(csrfToken).setUser(admin);
  }
}
