import React from "react";
import { Button } from "antd";

const NotFound404: React.FC = () => {
  return (
    <div>
      <Button type="primary">Page not found</Button>
    </div>
  );
};

export default NotFound404;
