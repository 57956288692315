import { API } from "../..";

export default class EventGateway {
  getEventsFromTo(currentDateRange: any) {
    return API.post("event/between", {
      from: new Date(currentDateRange.start).toISOString(),
      to: new Date(currentDateRange.end).toISOString()
      // from: "2021-12-27T00:00:00.000Z",
      // to: "2022-02-06T00:00:00.000Z"
    });
  }

  store(payload: any) {
    return API.post("event", payload);
  }

  update(payload: any) {
    return API.patch(`event/${payload.id}`, payload);
  }

  delete(id: string) {
    return API.delete(`event/${id}`);
  }
}
