import ClientEntity from "./ClientEntity";

export default class ClientViewMapper {
  static map(clients: Array<ClientEntity>) {
    return clients.map((client: ClientEntity) => {
      return {
        id: client.id.value,
        name: client.name.value,
        color: client.color.value,
        createdAt: client.createdAt,
        updatedAt: client.updatedAt
      };
    });
  }
}
