import CredentialsEntity from "./CredentialsEntity";
import CredentialsFactory from "./CredentialsFactory";
import CredentialsGateway from "./CredentialsGateway";
import CredentialsRepository from "./CredentialsRepository";
import MeEntity from "./MeEntity";
import Collection from "../valueObject/Collection";

export default class CredentialsService {
  // TODO Refaktorisati da koristi Factory umesto da API vraca reponse direktno u business logiku
  static login(credentials: any): Promise<any> {
    return CredentialsGateway.login(credentials);
  }

  static logout(): Promise<any> {
    return CredentialsGateway.logout();
  }

  static sendResetPasswordRequest(email: any): Promise<any> {
    return CredentialsGateway.sendResetPasswordRequest(email);
  }

  static setNewPassword(payload: Collection): Promise<any> {
    return CredentialsGateway.setNewPassword(payload);
  }

  /**
   * @returns Promise <MeEntity>
   */
  static me(): Promise<MeEntity> {
    return new CredentialsRepository(
      new CredentialsGateway(),
      new CredentialsFactory()
    ).getMe();
  }
}
