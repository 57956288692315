import BaseValueObject from "./BaseValueObject";

export default class TypeString extends BaseValueObject {
  /**
   * @param {String} value
   */
  constructor(value: string) {
    if (typeof value !== "string") {
      throw new TypeError(
        `Expecting type string instead got type ${typeof value}`
      );
    }
    super(value);
  }

  contains(value: string) {
    return this._value.indexOf(value) > 0;
  }
}
