import { setPopup } from "../../store/auth";
import store from "../../store/storeIndex";
import PopupRequest from "./PopupRequest";

export default class PopupsController {
  static _popupRequests = {
    login: new PopupRequest(),
    timesheet: new PopupRequest(),
    moreEvents: new PopupRequest()
  };

  static _popupStates = {
    login: false,
    timesheet: false,
    moreEvents: false
  };

  static openLoginPopup(request: PopupRequest | undefined = undefined) {
    if (request instanceof PopupRequest) this._popupRequests.login = request;
    store.dispatch(setPopup({ ...this._getStatesCopy(), login: true }));
  }

  static closeLoginPopup() {
    store.dispatch(setPopup({ ...this._getStatesCopy(), login: false }));
  }

  static openTimesheetPopup(request: PopupRequest | undefined = undefined) {
    if (request instanceof PopupRequest)
      this._popupRequests.timesheet = request;
    store.dispatch(setPopup({ ...this._getStatesCopy(), timesheet: true }));
  }

  static closeTimesheetPopup() {
    store.dispatch(setPopup({ ...this._getStatesCopy(), timesheet: false }));
  }

  static openMoreEventsPopup(request: PopupRequest | undefined = undefined) {
    if (request instanceof PopupRequest)
      this._popupRequests.moreEvents = request;
    store.dispatch(setPopup({ ...this._getStatesCopy(), moreEvents: true }));
  }

  static closeMoreEventsPopup() {
    store.dispatch(setPopup({ ...this._getStatesCopy(), moreEvents: false }));
  }

  static getLoginPopupRequest() {
    return this._popupRequests.login;
  }

  static getTimesheetPopupRequest() {
    return this._popupRequests.timesheet;
  }

  static getShowMoreEventsPopupRequest() {
    return this._popupRequests.moreEvents;
  }

  /**
   * Values cannot be changed because they are persisted in redux state and it will not allow direct modification.
   */
  static _getStatesCopy() {
    return JSON.parse(JSON.stringify(this._popupStates));
  }
}
