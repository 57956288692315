import Collection from "../valueObject/Collection";
import ProjectEntity from "./ProjectEntity";

export default class ProjectFactory {
  reconstitute(data: Collection) {
    const projects: Array<ProjectEntity> = [];

    data.forEach((project: any) => {
      const projectInstance = this.makeEmpty();
      projectInstance
        .setId(project._id)
        .setClient(project.client)
        .setClientEmail(project.clientEmail)
        .setColor(project.color)
        .setCreatedAt(project.createdAt)
        .setName(project.name)
        .setUpdatedAt(project.updatedAt);
      projects.push(projectInstance);
    });

    return projects;
  }
  makeEmpty(): ProjectEntity {
    return new ProjectEntity();
  }
}
