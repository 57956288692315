import TypeString from "../valueObject/TypeString";
import CredentialsType from "./valueObject/CredentialsType";

export default class CredentialsEntity {
  _representer: TypeString;
  _payload: TypeString;
  _type: CredentialsType;

  constructor(
    representer: TypeString,
    payload: TypeString,
    type: CredentialsType
  ) {
    this._representer = representer;
    this._payload = payload;
    this._type = type;
  }

  get representer() {
    return this._representer;
  }

  get payload() {
    return this._payload;
  }

  get type() {
    return this._type;
  }
}
