import ReactDOM from "react-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-calendar/dist/Calendar.css";
import "./assets/scss/style.scss";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/storeIndex";
import APIClient from "./utils/http/APIClient";
import { BrowserRouter } from "react-router-dom";

// Initialized as singleton by using export
export const API = new APIClient();

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root") || document.createElement("div")
);
