import ProjectEntity from "./ProjectEntity";

export default class ProjectViewMapper {
  static map(projects: Array<ProjectEntity>) {
    return projects.map((project: ProjectEntity) => {
      return {
        id: project.id,
        client: project.client,
        clientEmail: project.clientEmail,
        color: project.color,
        createdAt: project.createdAt,
        name: project.name,
        updatedAt: project.updatedAt
      };
    });
  }
}
