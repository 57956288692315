import Collection from "../valueObject/Collection";
import TagEntity from "./TagEntity";

export default class TagFactory {
  reconstitute(data: Collection) {
    const tags: Array<TagEntity> = [];

    data.forEach((tag: any) => {
      const tagInstance = this.makeEmpty();
      tagInstance
        .setId(tag._id)
        .setColor(tag.color)
        .setCreatedAt(tag.createdAt)
        .setName(tag.name)
        .setUpdatedAt(tag.updatedAt);
      tags.push(tagInstance);
    });

    return tags;
  }
  makeEmpty() {
    return new TagEntity();
  }
}
