import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./storeIndex";

export interface Event {}

export const initialState: any = {
  events: []
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setEventsRedux: (state, action: PayloadAction<any>) => {
      state.events = action.payload;
    }
  }
});

export const { setEventsRedux } = eventSlice.actions;

const eventSliceState = (state: RootState) => state.event;

export default eventSliceState;
