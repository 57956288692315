import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form } from "antd";
import BlockEventRows from "./BlockEventRows";
import PartInputSelectField from "../parts/PartInputSelectField";
import PartInputField from "../parts/PartInputField";
import EventFactory from "../../domain/event/EventFactory";
import EventService from "../../domain/event/EventService";
import PartTextAreaInput from "../parts/PartTextAreaInput";

const BlockEventForm = ({
  projects,
  tags,
  clients,
  triggerReloadEvents,
  currentDate,
  onCancelClick
}: any) => {
  const [projectsByClient, setProjectsByClient] = useState([]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitted }
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        event: yup.array().of(
          yup.object().shape(
            {
              client: yup.string().when("contact", {
                is: (contact: any) => contact?.length > 0,
                then: yup.string().required("Please provide reference name"),
                otherwise: yup.string()
              }),
              project: yup.string().when("refName", {
                is: (refName: any) => refName?.length > 0,
                then: yup
                  .string()
                  .required("Please provide contact information"),
                otherwise: yup.string()
              })
            },
            [["client", "project"]]
          )
        )
      })
    ),
    mode: "onBlur",
    shouldFocusError: false
    // defaultValues: formDefaultValues
  });

  const selectedClient = watch("client");
  console.log(selectedClient);

  useEffect(() => {
    if (selectedClient) {
      const projectsByClientId = projects.filter(
        (project: any) => project.client === selectedClient
      );
      console.log(projectsByClientId);
      setProjectsByClient(projectsByClientId);
    }
  }, [selectedClient]);

  const onSubmit = async (data: any) => {
    // factory make new object from form
    // call endpoint to save event
    const formattedDate = moment(currentDate).format("YYYY-MM-DD");
    const formData = { ...data, formattedDate };
    const newEvent = EventFactory.makeNewFromForm(formData);
    try {
      await EventService.store(newEvent);
      triggerReloadEvents();
    } catch (error) {
      // trigger notification or toast messsage that something went wrong
    }
  };

  return (
    <div className="form-rows-wrap">
      <div className="form-row">
        <PartInputSelectField
          name="client"
          control={control}
          placeholder="Choose client"
          errors={errors}
          label="Client"
          options={clients}
        />
        <PartInputSelectField
          name="project"
          control={control}
          errors={errors}
          disabled={selectedClient === undefined}
          placeholder="Choose project"
          label="Project"
          options={projectsByClient}
        />
        <PartInputSelectField
          name="type"
          control={control}
          errors={errors}
          label="Type"
          disabled={selectedClient === undefined}
          placeholder="Choose type"
          options={tags}
        />
        <PartInputField
          name="hours"
          control={control}
          disabled={selectedClient === undefined}
          errors={errors}
          label="Hours"
        />
        <PartTextAreaInput
          name="description"
          control={control}
          errors={errors}
          disabled={selectedClient === undefined}
          label="Description"
          rows={1}
        />
      </div>
      <Form>
        <BlockEventRows control={control} errors={errors} />
      </Form>
      <div className="form-buttons-wrap">
        <button className="btn btn--transparent" onClick={onCancelClick}>
          Cancel
        </button>
        <button
          className="btn btn--primary"
          onClick={handleSubmit(onSubmit, (errors) => {
            console.log(errors, "form errors");
          })}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default BlockEventForm;
