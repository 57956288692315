import BaseValueObject from "./BaseValueObject";
import TypePositiveInteger from "./TypePositiveInteger";

export default class TypeTimestamp extends BaseValueObject {
  /**
   * @param {int} value
   */
  constructor(value: any) {
    if (value != parseInt(value)) {
      throw new TypeError(`Expecting type integer instead got ${typeof value}`);
    }
    super(parseInt(value));
  }
  copy() {
    return new TypeTimestamp(this._value);
  }
  /**
   * @returns {int}
   */
  get value() {
    return parseInt(this._value);
  }
  get minute() {
    return Math.ceil(this._value / 60);
  }
  /**
   * Checks if the value object value is older than specified seconds ago.
   *
   * @param {Number} seconds
   * @returns {boolean}
   */
  expiredMoreThanSecondsAgo(seconds: number) {
    const now = Math.round(new Date().getTime() / 1000);
    // 3000 - 500 > 2000 true
    // 2400 - 500 > 2000 false
    return now - seconds > this._value;
  }
  /**
   * @returns {TypeTimestamp}
   */
  static makeNow() {
    return new TypeTimestamp(Math.round(new Date().getTime() / 1000));
  }
  /**
   * @param {string} formatString
   * @returns {string}
   */
  toDateFormat(formatString: string) {
    // available format characters: bfkqx CEJKQRVX
    const formatLookup: any = {
      j: (date: any) => {
        return date.getDate();
      }, // Returns the day of the month (from 1-31)
      d: (date: any) => {
        return new TypePositiveInteger(date.getDate()).addLeadingZeros(2);
      }, // Returns the day of the month (from 01-31)
      w: (date: any) => {
        return date.getDay();
      }, //Returns the day of the week (from 0-6)
      l: (date: any) => {
        return this._getDayTranslation(date.getDay());
      },
      y: (date: any) => {
        return date.getFullYear().toString().substr(-2);
      }, // A short numeric representation of a year, 2 digits
      Y: (date: any) => {
        return date.getFullYear();
      }, // A full numeric representation of a year, 4 digits
      G: (date: any) => {
        return date.getHours();
      }, // Returns the hour (from 0-23)
      g: (date: any) => {
        let hours = date.getHours();
        if (hours - 12 >= 0) hours = hours - 12;
        if (hours === 0) hours = 12;
        return hours;
      }, // Returns the hour (from 1-12)
      A: (date: any) => {
        return date.getHours() >= 12 ? "PM" : "AM";
      }, // Returns AM or PM
      H: (date: any) => {
        return new TypePositiveInteger(date.getHours()).addLeadingZeros(2);
      }, // Returns the hour (from 00-23)
      v: (date: any) => {
        return date.getMilliseconds();
      }, // Returns the milliseconds (from 0-999)
      i: (date: any) => {
        return new TypePositiveInteger(date.getMinutes()).addLeadingZeros(2);
      }, // Returns the minutes (from 00-59)
      m: (date: any) => {
        return new TypePositiveInteger(date.getMonth() + 1).addLeadingZeros(2);
      }, // Returns the month (from 01-12)
      n: (date: any) => {
        return date.getMonth() + 1;
      }, // Returns the month (from 1-12)
      F: (date: any) => {
        return this._getMonthTranslation(date.getMonth());
      },
      s: (date: any) => {
        return new TypePositiveInteger(date.getSeconds()).addLeadingZeros(2);
      }, // Returns the seconds (from 00-59)
      U: (date: any) => {
        return date.getTime() / 1000;
      } // Returns the number of seconds since midnight Jan 1 1970, and a specified date /1000
    };
    const newDate = new Date(this._value * 1000);
    let finalString = "";
    for (let i = 0, len = formatString.length; i < len; i++) {
      if (formatString[i] in formatLookup) {
        finalString += formatLookup[formatString[i]](newDate);
      } else {
        finalString += formatString[i];
      }
    }
    return finalString;
  }

  /**
   *
   * @returns {boolean} Returns true if given timestamp is Today
   */
  isToday() {
    const date = new Date(this._value * 1000);
    const today = new Date();
    return (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    );
  }

  /**
   *
   * @param {number} timestamp
   * @param {boolean} isToday
   * @returns {string} Converted date format with Today, h:mm | M/DD/YY h:m
   */
  // convertUnixToDate(timestamp: number, isToday: boolean): string {
  //   if (isToday) return moment.unix(timestamp).format("[Today], h:mmA");
  //   return moment.unix(timestamp).format("M/DD/YY h:mmA");
  // }

  _getDayTranslation(day: any) {
    const dayTranslations = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    return dayTranslations[day];
  }
  _getMonthTranslation(month: any) {
    const monthTranslations = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "Jully",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    return monthTranslations[month];
  }
}
