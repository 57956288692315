const IconWarning = ({ width, height, fill, className, style }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`custom-icon ${className ? className : ""}`}
      style={style}
      viewBox="0 0 16 15"
    >
      <g>
        <g>
          <path
            fill={fill}
            d="M6.68 5.909a.328.328 0 0 1 .328-.346h1.734c.188 0 .337.158.327.346l-.203 3.718a.328.328 0 0 1-.327.31H7.21a.328.328 0 0 1-.327-.31zm1.195 4.52a1.258 1.258 0 1 1 0 2.516 1.258 1.258 0 0 1 0-2.515zm7.698 2.353L9.012 1.406a1.313 1.313 0 0 0-2.274 0L.178 12.782a1.313 1.313 0 0 0 1.136 1.968h13.122a1.313 1.313 0 0 0 1.137-1.968z"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconWarning;
