import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import BlockLoginForm from "../components/blocks/BlockLoginForm";

const Login: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("isLoggedIn")) {
      history.replace("/");
    }
  }, []);

  return (
    <div className="login-block login-block--login">
      <div className="login-block__wrap">
        <div className="login-block__inner">
          <BlockLoginForm
            onLoginHandler={() => history.replace("/timesheet")}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
