import ProjectEntity from "../project/ProjectEntity";
import TagEntity from "../tag/TagEntity";
import TypeString from "../valueObject/TypeString";
import TypeTimestamp from "../valueObject/TypeTimestamp";

export default class EventEntity {
  _id: TypeString;
  _createdAt: TypeTimestamp;
  _date: Date;
  _description: TypeString;
  _project: ProjectEntity; // ProjectEntity
  _time: number; // TypeFloat later on...
  _type: TagEntity; // TypeEntity (Development, Meeting, Support, Debugging )
  _updatedAt: TypeTimestamp;
  _user: any; // UserEntity
  _eventsByDate: Array<any>;

  get id(): TypeString {
    return this._id;
  }
  setId(value: TypeString) {
    this._id = value;
    return this;
  }

  hasId() {
    return this._id instanceof TypeString;
  }

  get createdAt() {
    return this._createdAt;
  }

  setCreatedAt(value: TypeTimestamp) {
    this._createdAt = value;
    return this;
  }

  hasCreatedAt() {
    return this._createdAt instanceof TypeTimestamp;
  }

  get date(): Date {
    return this._date;
  }
  setDate(value: Date) {
    this._date = value;
    return this;
  }

  hasDate() {
    return this._date instanceof TypeTimestamp;
  }

  get description(): TypeString {
    return this._description;
  }
  setDescription(value: TypeString) {
    this._description = value;
    return this;
  }
  hasDescription() {
    return this._description instanceof TypeString;
  }

  get project(): any {
    return this._project;
  }
  setProject(value: ProjectEntity) {
    this._project = value;
    return this;
  }
  hasProject() {
    return this._project instanceof ProjectEntity;
  }

  get time(): number {
    return this._time;
  }
  setTime(value: number) {
    this._time = value;
    return this;
  }

  hasTime() {
    return this._time !== undefined;
  }

  get type(): any {
    return this._type;
  }
  setType(value: TagEntity) {
    this._type = value;
    return this;
  }

  hasType() {
    return this._type instanceof TagEntity;
  }

  get updatedAt(): TypeTimestamp {
    return this._updatedAt;
  }
  setUpdatedAt(value: TypeTimestamp) {
    this._updatedAt = value;
    return this;
  }
  hasUpdatedAt() {
    return this._updatedAt instanceof TypeTimestamp;
  }

  get user(): any {
    return this._user;
  }
  setUser(value: any) {
    this._user = value;
    return this;
  }
  hasUser() {
    //return this._user instanceof UserEntity;
  }

  get eventsByDate() {
    return this._eventsByDate;
  }

  setEventsByDate(value: Array<any>) {
    this._eventsByDate = value;
    return this;
  }
}
