import { useSelector } from "react-redux";
import authSliceState from "../../../store/auth";
import BlockEventForm from "../BlockEventForm";
import CloseIcon from "../../../assets/images/close.svg";
import PopupsController from "../../../utils/popup/PopupsController";
import { useEffect, useState } from "react";
import moment from "moment";
import ClientService from "../../../domain/client/ClientService";
import ClientViewMapper from "../../../domain/client/ClientViewMapper";

const BlockTimesheetPopup = ({ projects, tags, handleGetEvents }: any) => {
  const [clients, setClients] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState("");
  const authState = useSelector(authSliceState);
  const timesheetPopupRequest = PopupsController.getTimesheetPopupRequest();

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () => {
    const clients = await ClientService.getAll();
    console.log(clients);
    setClients(ClientViewMapper.map(clients));
  };

  useEffect(() => {
    setCurrentDate(timesheetPopupRequest.getInputData().items.date);
  }, [timesheetPopupRequest]);

  return (
    <div
      className={`popup popup--timesheet ${
        authState.popups.timesheet ? "visible" : ""
      }`}
    >
      <div
        className="popup__overlay"
        onClick={() => PopupsController.closeTimesheetPopup()}
      ></div>
      <div className="popup-inner">
        <span
          className="popup__close"
          onClick={() => PopupsController.closeTimesheetPopup()}
        >
          <img src={CloseIcon} alt="Close timesheet popup" />
        </span>
        <h2>{moment(currentDate).format("MMMM D, YYYY")}</h2>
        <BlockEventForm
          clients={clients}
          projects={projects}
          tags={tags}
          triggerReloadEvents={handleGetEvents}
          currentDate={currentDate}
          onCancelClick={() => PopupsController.closeTimesheetPopup()}
        />
      </div>
    </div>
  );
};

export default BlockTimesheetPopup;
