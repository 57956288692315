import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { authSlice } from "./auth";
import { commonSlice } from "./common";
import { eventSlice } from "./event";

const reducer = combineReducers({
  auth: authSlice.reducer,
  common: commonSlice.reducer,
  event: eventSlice.reducer
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
