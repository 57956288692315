import Collection from "../valueObject/Collection";
import TagGateway from "./TagGateway";
import TagFactory from "./TagFactory";

export default class TagRepository {
  _gateway: TagGateway;
  _factory: TagFactory;

  constructor(gateway: any, factory: any) {
    this._gateway = gateway;
    this._factory = factory;
  }

  async getTags() {
    const data = await this._gateway.getTags();
    return this._factory.reconstitute(new Collection(data.data));
  }
}
