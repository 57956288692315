import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./storeIndex";

export interface Common {
  isLoading: boolean;
}

export const initialState: Common = {
  isLoading: false
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    }
  }
});

export const { setIsLoading } = commonSlice.actions;

const commonSliceState = (state: RootState) => state.common;
export default commonSliceState;
