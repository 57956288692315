import TypeString from "../valueObject/TypeString";

export default class TagEntity {
  private _id: TypeString;
  private _color: TypeString;
  private _createdAt: Date;
  private _name: TypeString;
  private _updatedAt: Date;

  public get id(): TypeString {
    return this._id;
  }

  public setId(value: TypeString) {
    this._id = value;
    return this;
  }

  public get color(): TypeString {
    return this._color;
  }

  public setColor(value: TypeString) {
    this._color = value;
    return this;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public setCreatedAt(value: Date) {
    this._createdAt = value;
    return this;
  }

  public get name(): TypeString {
    return this._name;
  }

  public setName(value: TypeString) {
    this._name = value;
    return this;
  }

  public get updatedAt(): Date {
    return this._updatedAt;
  }

  public setUpdatedAt(value: Date) {
    this._updatedAt = value;
    return this;
  }
}
