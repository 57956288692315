import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";

export default class AdminEntity {
  _id: TypePositiveInteger;
  _firstName: TypeString;
  _lastName: TypeString;
  _email: TypeString;
  _role: TypeString;

  get id(): TypePositiveInteger {
    return this._id;
  }

  setId(value: TypePositiveInteger) {
    this._id = value;
    return this;
  }

  get firstName(): TypeString {
    return this._firstName;
  }

  setFirstName(value: TypeString) {
    this._firstName = value;
    return this;
  }

  get lastName(): TypeString {
    return this._lastName;
  }
  setLastName(value: TypeString) {
    this._lastName = value;
    return this;
  }

  get email(): TypeString {
    return this._email;
  }

  setEmail(value: TypeString) {
    this._email = value;
    return this;
  }
}
