import ClientFactory from "./ClientFactory";
import ClientGateway from "./ClientGateway";
import ClientRepository from "./ClientRepository";

export default class ClientService {
  static getAll(): Promise<any> {
    return new ClientRepository(
      new ClientGateway(),
      new ClientFactory()
    ).getAll();
  }
}
