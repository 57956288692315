import TypeString from "../valueObject/TypeString";

export default class ClientEntity {
  _id: TypeString;
  _name: TypeString;
  _color: TypeString;
  _createdAt: Date;
  _updatedAt: Date;

  get id() {
    return this._id;
  }

  setId(value: TypeString) {
    this._id = value;
    return this;
  }

  get name() {
    return this._name;
  }
  setName(value: TypeString) {
    this._name = value;
    return this;
  }

  get color() {
    return this._color;
  }
  setColor(value: TypeString) {
    this._color = value;
    return this;
  }

  get createdAt() {
    return this._createdAt;
  }
  setCreatedAt(value: Date) {
    this._createdAt = value;
    return this;
  }

  get updatedAt() {
    return this._updatedAt;
  }
  setUpdatedAt(value: Date) {
    this._updatedAt = value;
    return this;
  }
}
