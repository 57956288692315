import AdminEntity from "../admin/AdminEntity";
import TypeString from "../valueObject/TypeString";

export default class MeEntity {
  _token: TypeString;
  _user: AdminEntity; // TODO expand with other entities

  get token(): TypeString {
    return this._token;
  }

  setToken(value: TypeString) {
    this._token = value;
    return this;
  }

  get user(): AdminEntity {
    return this._user;
  }

  hasUser() {
    return this._user !== undefined;
  }

  setUser(user: AdminEntity) {
    this._user = user;
    return this;
  }
}
