import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { routes } from "./router/routerIndex";
import NotFound404 from "./views/NotFound404";
import PrivateRoute from "./router/PrivateRoute";
import PartLoadingSpinner from "./components/parts/PartLoadingSpinner";
import commonSliceState from "./store/common";
import MainHeader from "./components/main/MainHeader";
// import BlockLoginPopup from "./components/blocks/BlockPopup/BlockLoginPopup";
import CredentialsService from "./domain/credentials/CredentialsService";
import store from "./store/storeIndex";
import { setUserId, setUsername } from "./store/auth";

const LOGIN_SCREENS = ["/login", "/reset-password", "/new-password"];

const App: React.FC = () => {
  const [currentUrl, setCurrentUrl] = useState<any>(null);

  const commonState = useSelector(commonSliceState);

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn");
    if (loggedIn !== null) {
      getMe();
    }
  }, []);

  const getMe = async () => {
    const me = await CredentialsService.me();
    if (me.hasUser()) {
      localStorage.setItem("isLoggedIn", "true");
      const username = me.user.firstName.value;
      store.dispatch(setUsername(username));
      store.dispatch(setUserId(me.user.id.value));
    }
  };

  useEffect(() => {
    setCurrentUrl(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <div
      className={
        LOGIN_SCREENS.includes(currentUrl)
          ? "app-wrapper--login"
          : "app-wrapper--main"
      }
    >
      {commonState.isLoading && (
        <PartLoadingSpinner
          wrapperClassName="loading-spinner loading-spinner--full"
          size="large"
        />
      )}
      <Router>
        {/* <BlockLoginPopup /> */}
        {/* {!LOGIN_SCREENS.includes(window.location.pathname) ? (
          <MainHeader
            sidebarVisible={sidebarVisible}
            setSidebarVisible={setSidebarVisible}
          />
        ) : null} */}
        <div className="main">
          <main>
            <Switch>
              {routes.map((route, i) => {
                const props = {
                  key: i,
                  path: route.path,
                  exact: route.exact,
                  component: route.component
                };
                return route.isPrivate ? (
                  <PrivateRoute {...props} isPrivate={route.isPrivate} />
                ) : (
                  <Route {...props} />
                );
              })}
              <Route path="*" component={NotFound404} />
            </Switch>
          </main>
        </div>
      </Router>
    </div>
  );
};

export default App;
