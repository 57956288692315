import Collection from "../valueObject/Collection";
import CSRFTokenFactory from "./CSRFTokenFactory";
import CSRFTokenGateway from "./CSRFTokenGateway";

export class CSRFTokenService {
  static async getToken(): Promise<any> {
    const data = await new CSRFTokenGateway().getToken();
    return new CSRFTokenFactory().reconstitute(new Collection(data));
  }
}
