import Collection from "../valueObject/Collection";
import ClientFactory from "./ClientFactory";
import ClientGateway from "./ClientGateway";

export default class ClientRepository {
  _gateway: ClientGateway;
  _factory: ClientFactory;

  constructor(gateway: any, factory: any) {
    this._gateway = gateway;
    this._factory = factory;
  }

  async getAll() {
    const data = await this._gateway.getAll();
    return this._factory.reconstitute(new Collection(data.data));
  }
}
