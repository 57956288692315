import * as yup from "yup";

const PASSWORD_MINIMUM_CHARACTERS = 8;

// Rule: At least one number or special character, and one capital letter (8+ characters)
export const PASSWORD_REGULAR_EXPRESSION =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_0-9])(.{8,})$/;

export const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter valid email format")
    .required("This is a required field"),
  password: yup
    .string()
    .required("This is a required field")
    .min(
      PASSWORD_MINIMUM_CHARACTERS,
      `Password must have at least ${PASSWORD_MINIMUM_CHARACTERS} characters`
    )
});
