import Collection from "../valueObject/Collection";
import ProjectFactory from "./ProjectFactory";
import ProjectGateway from "./ProjectGateway";

export default class ProjectRepository {
  _gateway: ProjectGateway;
  _factory: ProjectFactory;

  constructor(gateway: any, factory: any) {
    this._gateway = gateway;
    this._factory = factory;
  }

  async getProjects() {
    const data = await this._gateway.getProjects();
    return this._factory.reconstitute(new Collection(data.data));
  }
}
