import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./storeIndex";

export interface Auth {
  csrf_token: string | null;
  userId: number;
  username: string | undefined;
  popups: any;
}

export const initialState: Auth = {
  csrf_token: null,
  userId: 0,
  username: undefined,
  popups: {}
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      state.csrf_token = action.payload;
    },
    setUserId: (state, action: PayloadAction<number>) => {
      state.userId = action.payload;
    },
    setUsername: (state, action: PayloadAction<string | undefined>) => {
      state.username = action.payload;
    },
    setLoggedOut: (state) => {
      state.csrf_token = null;
      state.userId = 0;
    },
    setPopup: (state, action: PayloadAction<any>) => {
      state.popups = action.payload;
    }
  }
});

export const { setToken, setUserId, setUsername, setLoggedOut, setPopup } =
  authSlice.actions;

const authSliceState = (state: RootState) => state.auth;
export default authSliceState;
