import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import authSliceState from "../../../store/auth";
import CloseIcon from "../../../assets/images/close.svg";
import PopupsController from "../../../utils/popup/PopupsController";
import moment from "moment";

const BlockShowMoreEventsPopup = () => {
  const [currentDate, setCurrentDate] = useState("");
  const authState = useSelector(authSliceState);
  const showMoreEventsPopupRequest =
    PopupsController.getShowMoreEventsPopupRequest();

  useEffect(() => {
    setCurrentDate(showMoreEventsPopupRequest.getInputData().items.date);
  }, [showMoreEventsPopupRequest]);
  return (
    <div
      className={`popup popup--day-detail ${
        authState.popups.moreEvents ? "visible" : ""
      }`}
    >
      <div
        className="popup__overlay"
        onClick={() => PopupsController.closeMoreEventsPopup()}
      ></div>
      <div className="popup-details">
        <span
          className="popup__close"
          onClick={() => PopupsController.closeMoreEventsPopup()}
        >
          <img src={CloseIcon} alt="Close timesheet popup" />
        </span>
        <div className="popup__header">
          <h2>{moment(currentDate).format("MMM D, YYYY")}</h2>
          <span>17.0</span>
        </div>
        {/* TO-DO: Replace with real values */}
        <div className="event event-group">
          <div className="event__client">
            <span className="event__client-name">Fine Fixtures</span>
            <span className="event__client-hours">6.0</span>
          </div>
          <div className="event__project">
            <span className="event__project-name">Fine Fixtures Part Site</span>
            <span className="event__project-hours">3.0</span>
          </div>
        </div>
        <div className="event event-group">
          <div className="event__client">
            <span className="event__client-name">Paradox</span>
            <span className="event__client-hours">2.5</span>
          </div>
          <div className="event__project">
            <span className="event__project-name">Paradox Ordering System</span>
            <span className="event__project-hours">2.5</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockShowMoreEventsPopup;
