import React from "react";

interface SpinnerProps {
  delay?: number;
  indicator?: any;
  size?: "default" | "small" | "large";
  spinning?: boolean;
  tip?: string;
  wrapperClassName?: string;
}

const PartLoadingSpinner: React.FC<SpinnerProps> = ({
  wrapperClassName
}: SpinnerProps) => {
  return (
    <div className={wrapperClassName}>
      <img src="" alt="FWS spinner animation" />
    </div>
  );
};

export default PartLoadingSpinner;
