import { useFieldArray } from "react-hook-form";
import PartInputSelectField from "../parts/PartInputSelectField";
import PartInputField from "../parts/PartInputField";

const BlockEventRows = ({ control, errors }: any) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "event"
  });

  return (
    <>
      {fields.map((field, index) => (
        <div className="form-row" key={field.id}>
          <PartInputSelectField
            name={`event.${index}.client` as const}
            label="Choose Client"
            control={control}
            errors={errors}
            isRequired
            placeholder="Choose Client"
            options={[]}
          />
          <PartInputSelectField
            name={`event.${index}.project` as const}
            label="Choose Project"
            control={control}
            errors={errors}
            isRequired
            placeholder="Choose Project"
            options={[]}
          />
          <PartInputSelectField
            name={`event.${index}.type` as const}
            label="Choose Type"
            control={control}
            errors={errors}
            isRequired
            placeholder="Choose Type"
            options={[]}
          />

          <PartInputField
            name={`event.${index}.hours` as const}
            label="Enter hours"
            control={control}
            errors={errors}
            // index={index}
          />

          <PartInputField
            name={`event.${index}.description` as const}
            label="Start typing here..."
            control={control}
            errors={errors}
            // index={index}
          />

          <button
            className="form-element__remove-event"
            // onClick={() => remove(index)}
          >
            Cancel
          </button>

          <button
            className="form-element__add-event"
            onClick={() => append({ refName: "", contact: "" })}
          >
            Save
          </button>
        </div>
      ))}
    </>
  );
};

export default BlockEventRows;
