export default class EventViewMapper {
  static map(events: any) {
    let eventsArray: any = [];
    events.forEach((event: any) => {
      const mappedEvent = event.clients.map((item: any) => {
        return {
          id: item.eventId,
          hours: item.time,
          title: item.client,
          allDay: true,
          color: item.color,
          description: item.description,
          project: item.project,
          type: item.type,
          start: new Date(item.date),
          end: new Date(item.date),
          totalHours: event.hours
        };
      });
      eventsArray = [...eventsArray, ...mappedEvent];
    });
    return eventsArray;
  }
}
