import Collection from "../../domain/valueObject/Collection";

export default class PopupRequest {
  _onSuccess: Function | undefined;
  _onFailure: Function | undefined;
  _inputData: Collection | undefined;
  _outputData: Collection | undefined;
  _promise: Promise<any>;
  _resolveCallback: Function | undefined;

  constructor() {
    // eslint-disable-next-line no-unused-vars
    this._promise = new Promise((resolve, reject) => {
      this._resolveCallback = resolve;
    });
  }

  setOnSuccess(callback: Function) {
    this._onSuccess = callback;
    return this;
  }

  getOnSuccess() {
    return typeof this._onSuccess === "function"
      ? () => {
          if (typeof this._onSuccess === "function") this._onSuccess();
          this.finish();
        }
      : () => {
          this.finish();
        };
  }

  setOnFailure(callback: Function) {
    this._onFailure = callback;
    return this;
  }

  getOnFailure() {
    return typeof this._onFailure === "function"
      ? () => {
          if (typeof this._onFailure === "function") this._onFailure();
          this.finish();
        }
      : () => {
          this.finish();
        };
  }

  setInputData(data: Collection) {
    this._inputData = data;
    return this;
  }

  getInputData() {
    return this._inputData instanceof Collection
      ? this._inputData
      : new Collection({});
  }

  setOutputData(data: Collection) {
    this._outputData = data;
    return this;
  }

  getOutputData() {
    return this._outputData instanceof Collection
      ? this._outputData
      : new Collection({});
  }

  wait() {
    return this._promise;
  }

  finish() {
    if (this._resolveCallback) this._resolveCallback();
  }
}
