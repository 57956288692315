import React, { FunctionComponent } from "react";
import { Redirect, Route } from "react-router-dom";

interface Props {
  path: string;
  exact: boolean | undefined;
  component: React.FC;
  isPrivate: boolean;
}

export const PrivateRoute: FunctionComponent<Props> = ({
  component: Component,
  ...rest
}: any) => {
  // const isLoggedIn = localStorage.getItem("isLoggedIn");
  const isLoggedIn = true;
  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
