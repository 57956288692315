import EventFactory from "./EventFactory";
import EventGateway from "./EventGateway";
import EventRepository from "./EventRepository";

export default class EventService {
  static getEvents(currentDateRange: any): Promise<any> {
    return new EventRepository(
      new EventGateway(),
      new EventFactory()
    ).getEvents(currentDateRange);
  }

  static store(payload: any): Promise<any> {
    return new EventGateway().store(payload);
  }

  static update(payload: any): Promise<any> {
    return new EventGateway().update(payload);
  }

  static delete(id: string): Promise<any> {
    return new EventGateway().delete(id);
  }
}
