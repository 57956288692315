import Collection from "../valueObject/Collection";
import EventFactory from "./EventFactory";
import EventGateway from "./EventGateway";

export default class EventRepository {
  _gateway: EventGateway;
  _factory: EventFactory;

  constructor(gateway: any, factory: any) {
    this._gateway = gateway;
    this._factory = factory;
  }

  async getEvents(currentDateRange: any) {
    const data = await this._gateway.getEventsFromTo(currentDateRange);
    return this._factory.reconstitute(new Collection(data.data));
  }
}
