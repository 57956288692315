import Collection from "../valueObject/Collection";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";
import AdminEntity from "./AdminEntity";

export default class AdminFactory {
  static reconstitute(data: Collection) {
    return new AdminEntity()
      .setId(new TypePositiveInteger(data.get("id")))
      .setFirstName(new TypeString(data.get("first_name")))
      .setLastName(new TypeString(data.get("last_name")))
      .setEmail(new TypeString(data.get("email")));
  }
}
