import { API } from "../..";
import Collection from "../valueObject/Collection";
import CredentialsEntity from "./CredentialsEntity";

export default class CredentialsGateway {
  static login(credentials: any) {
    // const loginData = {
    //   email: "jelena.ruzic@forwardslashny.com",
    //   password: "t95Lvh5SYn9E$"
    // };

    // const collectionData = new Collection({
    //   credentials: new Collection({
    //     representer: credentials.representer,
    //     type: credentials.type,
    //     payload: credentials.payload
    //   })
    // });

    return API.post("auth/login", credentials);
  }
  static logout(): Promise<any> {
    return API.get("Credentials/Logout");
  }

  static sendResetPasswordRequest(email: any): Promise<any> {
    const emailPayload = new Collection({ email });
    return API.get("Credentials/SendResetPassword", emailPayload);
  }

  static setNewPassword(payload: Collection): Promise<any> {
    return API.put("Credentials/ResetPassword", payload);
  }

  getMe(): Promise<any> {
    return API.get("Credentials/Me");
  }
}
