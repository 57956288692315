import Exception from "./Exception";

export default class ValidationException extends Exception {
  get invalid() {
    return this._data.invalid;
  }

  hasInvalid() {
    return "invalid" in this._data;
  }
}
