import Collection from "../valueObject/Collection";

export default class CredentialsRepository {
  _gateway: any;
  _factory: any;

  constructor(gateway: any, factory: any) {
    this._gateway = gateway;
    this._factory = factory;
  }

  async getMe() {
    const data = await this._gateway.getMe();

    return this._factory.reconstitute(new Collection(data));
  }
}
