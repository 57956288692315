import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import ClientEntity from "./ClientEntity";

export default class ClientFactory {
  reconstitute(data: Collection) {
    const clients: Array<ClientEntity> = [];

    data.forEach((client: any) => {
      const clientInstance = new ClientEntity();
      clientInstance
        .setId(new TypeString(client._id))
        .setName(new TypeString(client.name))
        .setColor(new TypeString(client.color))
        .setCreatedAt(client.createdAt)
        .setUpdatedAt(client.updatedAt);
      clients.push(clientInstance);
    });

    return clients;
  }
}
