import ProjectFactory from "./ProjectFactory";
import ProjectGateway from "./ProjectGateway";
import ProjectRepository from "./ProjectRepository";

export default class ProjectService {
  static getProjects(): Promise<any> {
    return new ProjectRepository(
      new ProjectGateway(),
      new ProjectFactory()
    ).getProjects();
  }
}
