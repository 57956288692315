import React from "react";
import { Controller } from "react-hook-form";
import { Select, Space } from "antd";
import { ErrorMessage } from "@hookform/error-message";
import IconChevronDown from "../base/svgIcons/IconChevronDown";

interface InputSelectFieldProps {
  name: string;
  label: string;
  control: any;
  errors: Object;
  isRequired?: boolean; //If true, adds * next to the input field
  disabled?: boolean;
  capitalizeFirstLetter?: any;
  validationMessage?: string;
  defaultValue?: string;
  placeholder?: React.ReactNode;
  options: Array<Object>;
}

const PartInputSelectField = ({
  name,
  label,
  control,
  errors,
  isRequired,
  disabled,
  validationMessage,
  placeholder,
  options = []
}: InputSelectFieldProps) => {
  return (
    <div
      className={`form-element form-element--select ${
        name in errors ? "form-element--error" : ""
      }`}
    >
      <label>
        {label}
        {isRequired && <span>{" *"}</span>}
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            style={{ width: 240 }} // TODO change this according to design
            disabled={disabled}
            placeholder={placeholder}
            showSearch
            suffixIcon={<IconChevronDown width={10} />}
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options.map((option: any) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <p className="form-element__error-msg">
            {validationMessage ? validationMessage : message}
          </p>
        )}
      />
      <Space />
    </div>
  );
};

export default PartInputSelectField;
