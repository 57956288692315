import Login from "../views/Login";
import IconUser from "../components/base/svgIcons/IconUser";
import Timesheet from "../views/Timesheet";

export const routes = [
  {
    path: "/timesheet",
    component: Timesheet,
    exact: true,
    isPrivate: true
  },
  {
    path: "/",
    component: Login,
    exact: true,
    isPrivate: false
  }
];

export const navLinks = [
  {
    path: "/seller-applications",
    title: "Seller Applications",
    icon: IconUser
  },
  {
    path: "/sellers",
    title: "Sellers",
    icon: IconUser
  }
];
