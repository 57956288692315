import TagEntity from "./TagEntity";

export default class TagViewMapper {
  static map(tags: Array<TagEntity>) {
    return tags.map((tag: any) => {
      return {
        id: tag._id,
        name: tag.name,
        color: tag.color,
        createdAt: tag.createdAt,
        updatedAt: tag.updatedAt
      };
    });
  }
}
