import { useEffect, useState } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import Calendar from "react-calendar";
import moment from "moment";
import EventService from "../domain/event/EventService";
import EventViewMapper from "../domain/event/EventViewMapper";
import ProjectService from "../domain/project/ProjectService";
import ProjectViewMapper from "../domain/project/ProjectViewMapper";
import TagService from "../domain/tag/TagService";
import TagViewMapper from "../domain/tag/TagViewMapper";
import BlockTimesheetPopup from "../components/blocks/BlockPopup/BlockTimesheetPopup";
import PopupsController from "../utils/popup/PopupsController";
import PopupRequest from "../utils/popup/PopupRequest";
import Collection from "../domain/valueObject/Collection";
import { hexToRgbA } from "../utils/utils";
import store from "../store/storeIndex";
import { setEventsRedux } from "../store/event";
import BlockShowMoreEventsPopup from "../components/blocks/BlockPopup/BlockShowMoreEventsPopup";

moment.locale("ko", {
  week: {
    dow: 1,
    doy: 1
  }
});
const localizer = momentLocalizer(moment);

const Timesheet = () => {
  const [events, setEvents] = useState<any>([]);
  const [eventsByDate, setEventsByDate] = useState([]);
  const [currentView, setCurrentView] = useState<any>("month");
  const [projects, setProjects] = useState<any>([]);
  const [tags, setTags] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState<any>(new Date());
  const [currentDateRange, setCurrentDateRange] = useState({
    start: moment().startOf("month"),
    end: moment().endOf("month")
  });

  let popupRequest = undefined;

  useEffect(() => {
    getEvents();
    getProjects();
    getTags();
  }, [currentDateRange]);

  const getEvents = async () => {
    const events = await EventService.getEvents(currentDateRange);
    setEventsByDate(events);
    const mappedEvents = EventViewMapper.map(events);
    store.dispatch(setEventsRedux(mappedEvents));
    setEvents(EventViewMapper.map(events));
  };

  const getProjects = async () => {
    const projects = await ProjectService.getProjects();
    setProjects(ProjectViewMapper.map(projects));
  };

  const getTags = async () => {
    const tags = await TagService.getTags();
    setTags(TagViewMapper.map(tags));
  };

  const handleAddEventClick = (date: any) => {
    popupRequest = new PopupRequest();
    popupRequest.setInputData(new Collection(date));
    PopupsController.openTimesheetPopup(popupRequest);
  };

  const CustomDateHeader = (props: any) => {
    const futureDate = moment(props.date).isAfter();
    const eventDate = moment(props.date).format("YYYY-MM-DD");
    const date = events.find((event: any) => {
      const date2 = moment(event.start).format("YYYY-MM-DD");
      return eventDate == date2;
    });
    return (
      <div className="month__day-header">
        {!futureDate ? (
          <div className="month__day-footer">
            <span
              className="month__day-cta"
              onClick={() => {
                handleAddEventClick(props);
              }}
            >
              add item
            </span>
            <span className="month__day-hours">
              {date?.totalHours}
              {date?.totalHours ? "h" : ""}
            </span>
          </div>
        ) : null}

        <span
          className="month__day-date"
          onClick={() => {
            setCurrentDate(props.date);
            setCurrentView("day");
          }}
        >
          {props.label}
        </span>
      </div>
    );
  };

  const handleShowMoreEvents = (events: any, date: any) => {
    popupRequest = new PopupRequest();
    popupRequest.setInputData(new Collection({ events, date }));
    PopupsController.openMoreEventsPopup(popupRequest);
    setCurrentView("month");
  };

  const CustomEventView = (props: any) => {
    if (currentView === "month") {
      return (
        <div className="month__day-custom" title="">
          <span
            title={props.title}
            className="month__day-title"
            style={{
              border: `1px solid ${props.event.color}`,
              backgroundColor: hexToRgbA(props.event.color, 0.1)
            }}
          >
            {props.title}
          </span>

          <span className="month__day-time">{props.event.hours}</span>
          <div
            title="Show more"
            className="show-more"
            onClick={() => {
              handleShowMoreEvents(props, props);
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      );
    }
    if (currentView === "week") {
      return (
        <div>
          {/* TO-DO: Replace with real values */}
          <div className="event event-group">
            <div className="event__client">
              <span className="event__client-name">
                {props.event.project.client.name}
              </span>
              <span className="event__client-hours">{props.event.hours}</span>
            </div>
            {/* <div className="event__project">
              <span className="event__project-name">
                Fine Fixtures Part Site
              </span>
              <span className="event__project-hours">3.0</span>
            </div> */}
          </div>
          {/* <div className="event event-group">
            <div className="event__client">
              <span className="event__client-name">Fine Fixtures</span>
              <span className="event__client-hours">6.0</span>
            </div>
            <div className="event__project">
              <span className="event__project-name">
                Fine Fixtures Part Site
              </span>
              <span className="event__project-hours">3.0</span>
            </div>
          </div> */}
        </div>
      );
    }
    if (currentView === "day") {
      return (
        <div>
          {/* TO-DO: Replace with real values */}
          <div className="event event-group">
            <div className="event__client">
              <span className="event__client-name">Fine Fixtures</span>
              <span className="event__client-hours">6.0</span>
            </div>
            <div className="event__project">
              <span className="event__project-name">
                Fine Fixtures Part Site
              </span>
              <span className="event__project-hours">3.0</span>
            </div>
          </div>
          <div className="event event-group">
            <div className="event__client">
              <span className="event__client-name">Fine Fixtures</span>
              <span className="event__client-hours">6.0</span>
            </div>
            <div className="event__project">
              <span className="event__project-name">
                Fine Fixtures Part Site
              </span>
              <span className="event__project-hours">3.0</span>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const components = {
    event: CustomEventView,
    month: {
      dateHeader: CustomDateHeader
    }
  };

  const handleOnRangeChange = (range: any, selectedView: string) => {
    // if (selectedView === "month")
    if (range.start && range.end)
      setCurrentDateRange({ start: range?.start, end: range?.end });
  };

  return (
    <div className="timesheet">
      <BlockTimesheetPopup
        projects={projects}
        tags={tags}
        handleGetEvents={getEvents}
      />
      <BlockShowMoreEventsPopup />
      <div className="container">
        <div className="row">
          <div className="timesheet-left">
            <Calendar
              onViewChange={(e: any) => {
                const label: any = document.querySelector(
                  ".react-calendar__navigation__label"
                );
                if (e.view === "year") {
                  label.classList.add("prevent-click");
                } else {
                  label.classList.remove("prevent-click");
                }
              }}
              showNeighboringMonth={false}
              formatMonth={(locale, date) =>
                moment(date, "YYYY-MM-DD HH:mm:ss").format("MMM")
              }
              formatShortWeekday={(locale, date) => {
                return moment(date, "YYYY-MM-DD HH:mm:ss")
                  .format("dd")
                  .slice(0, -1);
              }}
              onClickDay={(value: any) => {
                setCurrentDate(value);
                setCurrentView("day");
              }}
            />
          </div>
          <div className="timesheet-right">
            <BigCalendar
              date={currentDate}
              localizer={localizer}
              formats={{
                dateFormat: "D"
              }}
              events={events}
              startAccessor="start"
              endAccessor="end"
              view={currentView}
              components={components}
              style={{ height: "840px" }}
              views={["month", "week", "day"]}
              popup={false}
              onView={(view: string) => {
                setCurrentView(view);
              }}
              onRangeChange={(range: any, selectedView: any) =>
                handleOnRangeChange(range, selectedView)
              }
              onNavigate={(newDate: any) => {
                setCurrentDate(newDate);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timesheet;
