const IconChevronDown = ({ width, height, fill, className, style }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 7"
      width={width}
      height={height}
      className={`custom-icon ${className ? className : ""}`}
      style={style}
    >
      <g>
        <g>
          <path
            fill={fill}
            d="M5.266 6.119a.375.375 0 0 1-.532 0L.11 1.53A.375.375 0 0 1 .11 1L.73.381a.375.375 0 0 1 .53 0L5 4.084 8.74.381a.375.375 0 0 1 .532 0L9.89 1a.375.375 0 0 1 0 .531z"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconChevronDown;
