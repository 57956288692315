import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { CSRFTokenService } from "../../domain/CSRFToken/CSRFTokenService";
import BaseValueObject from "../../domain/valueObject/BaseValueObject";
import Collection from "../../domain/valueObject/Collection";
import { ErrorResponse } from "../../types/typesIndex";
import ValidationException from "../exceptions/ValidationException";
import PopupRequest from "../popup/PopupRequest";
import PopupsController from "../popup/PopupsController";
import { RequestInterface } from "./requestInterface";

export default class APIClient {
  _axiosClient = axios.create({
    baseURL: "https://rocky-escarpment-44695.herokuapp.com/api/v1/",
    withCredentials: false,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*"
    }
  });
  _loginPromise: Promise<any> | undefined;

  constructor() {
    const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
      if (config.data instanceof Collection)
        switch (config.method) {
          case "post":
            config.data = this._dataToFormData(config.data);
            break;
          case "put":
          case "delete":
          case "get":
            config.url += this._dataToQueryString(config.data);
            break;
        }

      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    };

    const onRequestError = (error: AxiosError): Promise<AxiosError> => {
      // ErrorLogger.log(error, "interceptor onRequestError");
      return Promise.reject(error);
    };

    const onResponse = (response: AxiosResponse): any => {
      return response.data ? response.data : undefined;
    };

    const onResponseError = async (
      error: ErrorResponse
    ): Promise<ErrorResponse> => {
      const errData = error.response?.data.message;
      let token;
      switch (errData) {
        case "CSRF_ERROR":
          token = await CSRFTokenService.getToken();
          localStorage.setItem("token", token.value);
          error.config.headers["X-CSRF-TOKEN"] = token.value;
          return this._axiosClient.request(error.config);
        // JWT Session has expired
        case "UNAUTHORIZED": {
          // Set old token
          const oldToken = error.response?.config.headers["X-CSRF-TOKEN"];
          // Request new CSRF token
          token = await CSRFTokenService.getToken();
          let popupRequest = undefined;
          if (token.value === oldToken) {
            if (!(this._loginPromise instanceof Promise)) {
              popupRequest = new PopupRequest();
              popupRequest.setOnFailure(() => {
                window.location.href = "/login";
              });
              PopupsController.openLoginPopup(popupRequest);
              this._loginPromise = popupRequest.wait();
            }
            await this._loginPromise;
          }
          localStorage.setItem("token", token.value);
          error.config.headers["X-CSRF-TOKEN"] = token.value;
          return this._axiosClient.request(error.config);
        }
        // break;
        case "VALIDATION_EXCEPTION":
          throw new ValidationException(
            error.response?.data.message,
            error.response?.data.code,
            error.response?.data.data
          );
      }
      return Promise.reject(error);
    };

    this._axiosClient.interceptors.request.use(onRequest, onRequestError);
    this._axiosClient.interceptors.response.use(onResponse, onResponseError);
  }

  get(endpoint: string, data?: Collection): any {
    return this._axiosClient.get(endpoint, {
      data: data
    });
  }

  post(endpoint: string, data?: any, config?: RequestInterface): any {
    const axiosConfig: AxiosRequestConfig = { ...config };
    return config
      ? this._axiosClient.post(endpoint, data, axiosConfig)
      : this._axiosClient.post(endpoint, data);
  }

  put(endpoint: string, data?: Collection): any {
    return this._axiosClient.put(endpoint, data);
  }

  delete(endpoint: string, data?: any): any {
    return this._axiosClient.delete(endpoint, { data: data });
  }

  patch(endpoint: string, data?: any): any {
    return this._axiosClient.patch(endpoint, { data: data });
  }

  _dataToFormData(data: Collection) {
    const formData = new FormData();
    data.forEach((value: any, key: any) => {
      if (value instanceof Collection) {
        // multilevel support
        value.forEach((subValue: any, subKey: any) => {
          const parsedValue =
            subValue instanceof BaseValueObject ? subValue.value : subValue;

          formData.append(`${key}[${subKey}]`, parsedValue);
        });
      } else {
        // single level support
        const parsedValue =
          value instanceof BaseValueObject ? value.value : value;

        formData.append(key, parsedValue);
      }
    });

    return formData;
  }

  _dataToQueryString(data: Collection) {
    const queryString: Array<any> = [];
    data.forEach((value: any, key: any) => {
      this._valueToQueryString(value, queryString, key);
    });
    return "?" + queryString.join("&");
  }

  _valueToQueryString(value: any, resultingItems: Array<any>, key: any) {
    if (Array.isArray(value))
      this._arrayToQueryString(value, resultingItems, key);
    else if (value instanceof Collection)
      this._collectionToQueryString(value, resultingItems, key);
    else {
      resultingItems.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(
          value instanceof BaseValueObject ? value.value : value
        )}`
      );
    }
  }

  _arrayToQueryString(array: Array<any>, resultingItems: Array<any>, key: any) {
    let index = 0;
    array.forEach((value) => {
      this._valueToQueryString(value, resultingItems, `${key}[${index++}]`);
    });
  }

  _associativeCollectionToQueryString(
    collection: Collection,
    resultingItems: Array<any>,
    key: any
  ) {
    collection.forEach((value: any, parameterKey: any) => {
      this._valueToQueryString(
        value,
        resultingItems,
        `${key}[${parameterKey}]`
      );
    });
  }

  _collectionToQueryString(
    collection: Collection,
    resultingItems: Array<any>,
    key: any
  ) {
    collection.isAssociative()
      ? this._associativeCollectionToQueryString(
          collection,
          resultingItems,
          key
        )
      : this._arrayToQueryString(collection.items, resultingItems, key);
  }
}
