import Collection from "./valueObject/Collection";

export default class BaseModel {
  private _afterChangeTriggers: Collection;
  private _afterAddTriggers: Collection;
  constructor() {
    this._afterChangeTriggers = new Collection({});
    this._afterAddTriggers = new Collection({});
  }
  afterChange(key: string, callback: any) {
    this._afterChangeTriggers.set(key, callback);
    return this;
  }

  _executeAfterChangeTriggers() {
    // eslint-disable-next-line no-unused-vars
    this._afterChangeTriggers.forEach((callback: any, key: string) => {
      callback();
    });
  }

  afterAdd(key: string, callback: any) {
    this._afterAddTriggers.set(key, callback);
    return this;
  }

  _executeAfterAddTriggers() {
    // eslint-disable-next-line no-unused-vars
    this._afterAddTriggers.forEach((callback: any, key: string) => {
      callback();
    });
  }
}
